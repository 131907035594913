import React, { useState } from 'react'
import ImageBackground from 'gatsby-background-image'

// Components
import ButtonDefault from 'components/elements/ButtonDefault'

// Third Party
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'
import { motion } from 'framer-motion'

const StyledFocus = styled.div`
  padding: 100px 0px 100px 0px;

  @media screen and (max-width: 991px) {
    padding: 20px 0px 20px 0px;
  }
`

const StyledBackground = styled(ImageBackground)`
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  position: absolute !important;
  top: 0;
  opacity: 1 !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.6) 20%, rgba(255,255,255,0.6) 80%, rgba(255,255,255,1) 100%);
`

const Corner = styled.div`
  position: absolute;

  ${props => props.position !== 'center' && css`
    width: 77px;
    height: 77px;
  `}

  ${props => props.position && {
    'topLeft': css`
      border-left: 2px solid rgba(0, 0, 0, 0.2);
      border-top: 2px solid rgba(0, 0, 0, 0.2);
      left: 60px;
      top: -50px;

      @media screen and (max-width: 767px) {
        left: 30px;
        top: -70px
      }

      @media screen and (max-width: 575px) {
        left: 10px;
      }
    `,
    'bottomLeft': css`
      border-left: 2px solid rgba(0, 0, 0, 0.2);
      border-bottom: 2px solid rgba(0, 0, 0, 0.2);
      left: 60px;
      bottom: 70px;

      @media screen and (max-width: 767px) {
        left: 30px;
      }

      @media screen and (max-width: 575px) {
        bottom: 10px;
        left: 10px;
      }
    `,
    'topRight': css`
      border-right: 2px solid rgba(0, 0, 0, 0.2);
      border-top: 2px solid rgba(0, 0, 0, 0.2);
      right: 60px;
      top: -50px;

      @media screen and (max-width: 767px) {
        right: 30px;
        top: -70px;
      }

      @media screen and (max-width: 575px) {
        right: 10px;
      }
    `,
    'bottomRight': css`
      border-right: 2px solid rgba(0, 0, 0, 0.2);
      border-bottom: 2px solid rgba(0, 0, 0, 0.2);
      right: 60px;
      bottom: 70px;

      @media screen and (max-width: 767px) {
        right: 30px;
      }

      @media screen and (max-width: 575px) {
        bottom: 10px;
        right: 10px;
      }
    `,
    'center': css`
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      top: 160px;
      width: 35px;
      height: 35px;

      @media screen and (max-width: 1199px) {
        top: 185px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 2px;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
      }
      
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        width: 100%;
        height: 2px;
        background: rgba(0, 0, 0, 0.2);
      }
    `
  }[props.position]}
`

const Content = styled.div`
  min-height: 126px;

  h2 {
    padding-bottom: 15px;

    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }

  p {
    padding: 0 200px 0 200px;
    text-align: center;

    @media screen and (max-width: 991px) {
      width: 100%;
      text-align: center;
      padding: 0 100px 0 100px;
    }

    @media screen and (max-width: 576px) {
      padding: 0 50px 0 50px;
    }
  }
`

const Button = styled.button`
  border: 2px solid transparent;
  background: ${props => props.theme.color.face.light};
  border-radius: 29.5px;
  width: 100%;
  padding: 5px 0 5px 0;
  position: relative;
  z-index: 2;

  p {
    color: rgba(0,0,0,0.3);
  }

  ${props => props.active && css`
    p {
      color: ${props.theme.color.text.main};
    }
  `}
`

const ButtonWrapper = styled(motion.div)`
  position: relative;
  width: 160px;
  border-radius: 29.5px;
  padding: 2px;
`

const ButtonShadow = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  border-radius: 29.5px;
  background: rgba(0,0,0,0.3);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  z-index: 1;

  ${props => props.active && css`
    background: ${props.theme.color.gradient.main};
  `}
`

const FocusText = styled.h2`
  text-shadow: 1px 1px 1px rgba(0,0,0,0.5);

  @media screen and (max-width: 991px) {
    color: ${props => props.theme.color.text.main};
  }
`

const Focus = ({ className, content }) => {
  const [ point, setPoint ] = useState(0)

  return (
    <StyledFocus className={`position-relative mt-lg-5 ${className ? `${className}` : ``}`}>
      <StyledBackground loading="eager" fadeIn={false} alt="" fluid={content.image.localFile.childImageSharp.fluid} />
      <div className="container pt-5">
        <FocusText className="font-weight-xl text-center">Wij houden focus</FocusText>
        <div className="row position-relative py-5">
          <Corner position="topLeft" />
          <Corner position="bottomLeft" />
          <Corner position="topRight" />
          <Corner position="bottomRight" />
          <Corner className="d-lg-block d-none" position="center" />
          <div className="col-lg-12 d-flex justify-content-center">
            <div className="row pb-lg-5">
              {content.points.map((c, index) => (
                <div className="col-sm-4 d-flex justify-content-center">
                  <ButtonWrapper
                    className="mb-lg-0 mb-4"
                    whileHover={{
                      scale: 1.1,
                      transition: {
                        duration: .3,
                        type: 'spring'
                      }
                    }}
                  >
                    <Button active={index === point} type="button" onClick={() => setPoint(index)}>
                      <p className="mb-0 font-size-xm font-weight-xl">{c.title}</p>
                    </Button>
                    <ButtonShadow active={index === point} />
                  </ButtonWrapper>
                  
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-12 pt-lg-5 mt-lg-5 d-flex align-items-center justify-content-center">
            <Content className="d-lg-block d-flex">
              {parse(content.points[point].description)}
            </Content>
          </div>
          <div className="col-12 d-flex justify-content-center pt-md-5">
            <ButtonDefault className="mt-4" icon="arrow" to={content.link.url}>
              <p>{content.link.title}</p>
            </ButtonDefault>
          </div>
        </div>
      </div>
    </StyledFocus>
  )
}

export default Focus