import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Customer from 'components/shared/Customer'

// Third Party
import styled from 'styled-components'

const StyledRegisseurs = styled.div``

const Row = styled.div`
  padding-top: 150px;
  margin-left: -60px;
  margin-right: -60px;
`

const Wrapper = styled.div`
  padding: 0 25px 150px 25px;

  @media screen and (max-width: 1199px) {
    padding: 0 5px 150px 5px;
  }

  @media screen and (max-width: 576px) {
    padding: 0 75px 150px 75px;
  }
`

const Regisseurs = ({ className }) => {
  const {
    employees: { nodes: employees },
  } = useStaticQuery(graphql`
    {
      employees: allWordpressWpMedewerker(
        filter: { wordpress_id: { ne: 2886 } }
      ) {
        nodes {
          ...EmployeesFrag
        }
      }
    }
  `)

  return (
    <StyledRegisseurs
      className={`container ${className ? `${className}` : ``}`}
    >
      <h2 className="font-weight-xl font-size-xml">
        Het team van Clear Mind IT Consultancy
      </h2>
      <Row className="row">
        {employees.map((e) => (
          <Wrapper className="col-md-3">
            <Customer key={e.wordpress_id} content={e} />
          </Wrapper>
        ))}
      </Row>
    </StyledRegisseurs>
  )
}

export default Regisseurs
