
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

// Components
import ButtonShell from 'components/elements/ButtonShell'

// Images
import ArrowButton from 'img/icon/arrow_button.inline.svg'
import userButton from 'img/icon/user_button.svg'

const StyledButton = styled(motion.div)`
  position: relative;
  background: transparent;
  border: 2px solid ${props => props.theme.color.face.light};
  border-radius: 29.5px;
  display: inline-block;
  height: 49px;

  ${props => props.min && css`
    min-width: ${props.min};
  `}
  
  & > a, & > button {
    color: ${props => props.theme.color.text.light};
    font-weight: ${props => props.theme.font.weight.xl};
    padding: 10px 50px;
    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: ${props => props.theme.color.text.light};
    }
  }
`

const IconWrapper = styled(motion.div)`
  position: absolute;
  top: 7px;
  pointer-events: none;
  height: 31px;
  width: 31px;

  img, svg {
    height: 100%;
    width: 100%;

    path {
      fill: ${props => props.theme.color.text.light};
      stroke: ${props => props.theme.color.text.light};
    }
  }
`

const ButtonLine = ({ min, icon, isAnchor, isCustom, to, children, className }) => (
  <StyledButton
    initial="rest"
    whileHover="hover"
    animate="rest"
    className={className}
    min={min}
  >
    {{
      'arrow': <IconWrapper variants={{ rest: { left: '8px' }, hover: { left: 'calc(100% - 39px)', transition: { duration: .3 } } }}><ArrowButton /></IconWrapper>,
      'user': <IconWrapper variants={{ rest: { left: '8px' }, hover: { rotate: ['0deg', '20deg', '-20deg', '0deg'], transition: { duration: .3 } } }}><img src={userButton} alt="" /></IconWrapper>
    }[icon]}
    {isCustom ? children : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonLine